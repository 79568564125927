<template lang="pug">
.vc-odd-pay-invoice-section-wrapper
  .section-title
    .wrapper
      icon(:icon="iconClass")
      span {{ title }}
    a.trigger(
      @click="triggerSection"
      :class="sectionOpened ? '' : 'closed'"
    )
      icon(icon="mdi:chevron-down")
  .odd-section-body(:class="{ closed: !sectionOpened }")
    slot
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue2'
import { ref } from 'vue'

defineProps({
  title: {
    type: String,
    required: true
  },

  iconClass: {
    type: String,
    required: true
  }
})

const sectionOpened = ref(true)

const triggerSection = () => {
  sectionOpened.value = !sectionOpened.value
}
</script>

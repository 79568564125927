<template lang="pug">
.vc-odd-pay-invoice-make-payment-button
  .button(
    :class="buttonClass"
    :disabled="disabled"
    @click="makePayment"
  ) {{ actionLocaleText('make_payment') }}
  b-modal(:active.sync="choosingPaymentMethod")
    .box
      .label 請選擇付款方式
      b-radio(
        v-model="invoiceForm.payment_method_id"
        v-for="paymentMethod in availablePaymentMethods"
        :key="paymentMethod.id"
        :native-value="paymentMethod.id"
        @input="choosePaymentMethod"
      ) {{ paymentMethod.name }}
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue'
import { useStore, useInstance } from 'skid-composables'
import Form from 'odd-form_object'

const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },

  paymentMethodId: {
    type: String,
    required: false
  },

  buttonClass: {
    type: String,
    required: false,
    default: () => 'is-outlined is-default'
  },

  disabled: {
    type: Boolean,
    required: false
  },

  beforeProcess: {
    type: Function,
    required: false
  },

  paymentType: {
    type: Array,
    required: false,
    default: () => []
  }
})

const store = useStore()
const vueInstance = useInstance()
const postInfo = ref(null)
const invoiceForm = reactive(new Form(props.invoice))

const choosingPaymentMethod = ref(false)

const availablePaymentMethods = computed(
  () => store.getters['oddPayPaymentMethods/all']
)

const paymentMethodCount = computed(() => availablePaymentMethods.value.length)
const processing = ref(false)

const disabled = computed(() => props.disabled || processing.value)

const makePayment = async () => {
  if (disabled.value) return

  try {
    if (props.beforeProcess) {
      await props.beforeProcess()
    }

    if (props.paymentMethodId) {
      invoiceForm.payment_method_id = props.paymentMethodId
      await makePaymentForOddPayInvoice()
      return buildAndSubmitForm()
    }

    await store.dispatch('oddPayPaymentMethods/all', {
      search: {
        enabled_true: 1,
        payment_type_in: props.paymentType
      }
    })

    switch (paymentMethodCount.value) {
      case 0:
        throw 'No payment method for now'
      case 1:
        invoiceForm.payment_method_id = availablePaymentMethods.value[0].id
        await makePaymentForOddPayInvoice()
        buildAndSubmitForm()
        break
      default:
        choosingPaymentMethod.value = true
    }
  } catch (error) {
    const element = document.querySelector('.is-danger')
    if (!element) return

    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

const makePaymentForOddPayInvoice = () => {
  const buyerType = {
    User: 'users',
    Partner: 'partners'
  }[props.invoice.buyer_type]

  return store
    .dispatch(`${buyerType}/makePaymentForOddPayInvoice`, invoiceForm.sync())
    .then((response) => (postInfo.value = response.data))
}

const buildAndSubmitForm = () => {
  const form = document.createElement('form')

  form.setAttribute('action', postInfo.value.post_url)
  form.setAttribute('method', 'post')

  const postParams = postInfo.value.post_params
  const paramKeys = Object.keys(postParams)

  paramKeys.map((key) => {
    let input = document.createElement('input')

    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute('value', postParams[key])
    form.appendChild(input)
  })
  processing.value = true
  vueInstance.$el.appendChild(form)
  form.submit()
}

const choosePaymentMethod = async () => {
  await makePaymentForOddPayInvoice()
  buildAndSubmitForm()
}
</script>
